import React, {
  createContext,
  useCallback,
  useContext,
  useReducer,
} from "react";
import dfSubDays from "date-fns/subDays";
import { formatDate, getDateByTimeZone } from "@/lib/date";
import { Delivery } from "@/Models";
import { useApiStore } from "@/store/hooks";

export const QUERY_DATE_TYPE_MISCLASSIFIED_DATE = "MISCLASSIFIED_DATE";

export const CONSTANTS = {
  SET_QUERY: "SET_QUERY",
  FETCH_ALL: "FETCH_ALL",
  RESET: "RESET",
};

const zonedDate30days = dfSubDays(getDateByTimeZone(), 30);
const zonedDate7days = dfSubDays(getDateByTimeZone(), 7);
const zonedDateToday = getDateByTimeZone();
export const initDate30days = formatDate(zonedDate30days);
export const initDateFrom = formatDate(zonedDate7days);
export const initDateTo = formatDate(zonedDateToday);

const INITIAL_QUERY = {
  bookId: "",
  dateType: QUERY_DATE_TYPE_MISCLASSIFIED_DATE,
  dateFrom: initDateFrom,
  dateTo: initDateTo,
  orderIdFromCorp: "",
  spotName: "",
  types: "1;2;3",
};

const INITIAL_STATE = {
  deliveries: [],
  query: INITIAL_QUERY,
};

const reducer = (state, action) => {
  switch (action.type) {
    case CONSTANTS.FETCH_ALL:
      return {
        ...state,
        deliveries: action.deliveries,
        pageCount: action.pageCount,
      };
    case CONSTANTS.SET_QUERY:
      return {
        ...state,
        query: {
          ...state.query,
          ...action.query,
        },
      };
    default:
      return INITIAL_STATE;
  }
};

export const Context = createContext(INITIAL_STATE);

export const Provider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, INITIAL_STATE);

  return (
    <Context.Provider value={{ dispatch, state }}>{children}</Context.Provider>
  );
};

export const useDeliveriesMisclassificationStore = () => {
  const api = useApiStore();
  const { dispatch, state } = useContext(Context);

  const fetchAll = useCallback(async () => {
    const { rows } = await api.get("/deliveries/misclassified", {
      dateFrom: initDate30days,
      dateTo: initDateTo,
    });

    let deliveries = rows.map((delivery) => new Delivery(delivery));
    dispatch({
      type: CONSTANTS.FETCH_ALL,
      deliveries,
    });

    return { deliveries };
  }, [api.get, dispatch]);

  const setQuery = useCallback(
    (query = {}) => {
      dispatch({
        type: CONSTANTS.SET_QUERY,
        query,
      });
    },
    [dispatch],
  );

  const setMisclassification = useCallback(
    async ({ bookIds }) => {
      return await Promise.allSettled(
        bookIds.map((bookId) =>
          api.put(`/deliveries/${bookId}/misclassification`, {
            misclassification: true,
          }),
        ),
      );
    },
    [api.put],
  );

  const setUnmisClassification = useCallback(
    (bookId) =>
      api.put(`/deliveries/${bookId}/misclassification`, {
        misclassification: false,
      }),
    [api.put],
  );

  return {
    state,
    fetchAll,
    setQuery,
    setMisclassification,
    setUnmisClassification,
  };
};
